import React, { type ReactNode, type FC, useCallback } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import type { AppError } from '@appTypes';
import { Feedback } from '@components/Feedback';
import { Button } from '@components/Button';
import { DataHooks } from './DataHooks';

type Props = {
  children: ReactNode;
  error?: AppError;
};

export const ApplicationError: FC<Props> = ({ children, error }) => {
  const { t } = useTranslation();
  const resolvedError = error?.resolvedError;

  const handleClick = useCallback(() => {
    resolvedError?.action?.onClick?.();
  }, [resolvedError?.action]);

  if (!resolvedError) {
    return <>{children}</>;
  }

  const showAction =
    Boolean(resolvedError.action?.text) &&
    typeof resolvedError.action?.onClick === 'function';

  return (
    <Feedback
      title={t('app.error-page.fetch-failed.title')}
      subtitle={resolvedError.message}
      subtitleDataHook={DataHooks.ErrorText}
      actions={
        showAction && (
          <Button
            wiringVariant="primary"
            data-hook={DataHooks.ErrorCtaButton}
            onClick={handleClick}
          >
            {resolvedError.action?.text}
          </Button>
        )
      }
    />
  );
};
