import React, {
  type FC,
  type SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Image } from 'wix-ui-tpa/cssVars';
import ccNoBorderIconURI from '@assets/icons/creditCardNoBorder.svg?url';
import { Skeleton } from '@components/Skeleton';
import { st, classes } from './PaymentMethodIcon.st.css';

type Props = {
  url: string;
  altText?: string;
  isLoading?: boolean;
};

export const PaymentMethodIcon: FC<Props> = ({
  url,
  altText = '',
  isLoading,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleError = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = ccNoBorderIconURI;
  }, []);

  const handleLoad = useCallback(() => {
    setIsImageLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoading) {
      setIsImageLoaded(false);
    }
  }, [isLoading]);

  return (
    <>
      {!isImageLoaded && <Skeleton />}
      {!isLoading && (
        <Image
          src={url}
          alt={altText}
          width={45}
          height={28}
          className={st(classes.root, { hidden: !isImageLoaded })}
          errorMessage=""
          onError={handleError}
          onLoad={handleLoad}
        />
      )}
    </>
  );
};
